var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.generatorCdfs != null
    ? _c(
        "section",
        { staticClass: "generator-cdfs" },
        [
          _c(
            "v-card",
            { staticClass: "elevation-10 pa-4" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-space-between": "" } },
                [
                  _c("v-flex", { staticClass: "header" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v("\n          Olá, "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.currentUser.person.name) +
                            " (" +
                            _vm._s(_vm.cpf_cnpj) +
                            ")"
                        ),
                      ]),
                      _vm._v(
                        ".\n          Você tem " +
                          _vm._s(_vm.generatorCdfs.length) +
                          " CDF's:\n        "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { "align-center": "" } },
                [
                  _c("filter-cards", {
                    attrs: { cards: _vm.cards, activeStatus: _vm.activeStatus },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "mt-3", attrs: { fluid: "" } },
            [
              _vm._l(_vm.cdfsFiltereds, function (cdf, index) {
                return !_vm.isFetchingCdfs
                  ? _c(
                      "v-card",
                      { key: index, staticClass: "mb-4 elevation-3" },
                      [
                        _c(
                          "v-toolbar",
                          { attrs: { color: "grey lighten-4", flat: "" } },
                          [
                            _c(
                              "h2",
                              { staticClass: "subheading font-weight-medium" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(cdf.number) +
                                    "\n        "
                                ),
                              ]
                            ),
                            _c(
                              "v-chip",
                              {
                                staticClass: "ml-5 mr-3 text-uppercase",
                                attrs: {
                                  "text-color": "white",
                                  color: "grey darken-2",
                                  small: "",
                                },
                              },
                              [_vm._v("\n          Emitido\n        ")]
                            ),
                            _c("span", { staticClass: "caption" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.format(cdf.created_at, "DD/MM/YYYY")
                                  ) +
                                  "\n          às\n          " +
                                  _vm._s(_vm.format(cdf.created_at, "HH:mm")) +
                                  "\n        "
                              ),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-capitalize",
                                attrs: {
                                  color: "grey darken-2",
                                  flat: "",
                                  disabled: _vm.isRequesting,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showCdfPdf(cdf)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-certificate"),
                                ]),
                                _vm._v("\n          Visualizar\n        "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "align-center": "",
                                  wrap: "",
                                  row: "",
                                },
                              },
                              [
                                _c(
                                  "v-flex",
                                  { staticClass: "mb-3", attrs: { xs6: "" } },
                                  [
                                    _c(
                                      "v-subheader",
                                      {
                                        staticClass:
                                          "pl-0 caption text-uppercase",
                                        staticStyle: { height: "32px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Destinador\n            "
                                        ),
                                      ]
                                    ),
                                    _c("h3", { staticClass: "subheading" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(cdf.receiver_data.name) +
                                          "\n            "
                                      ),
                                    ]),
                                    _c("span", { staticClass: "grey--text" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            cdf.receiver_data.cpf_cnpj
                                              .length === 14
                                              ? "CNPJ"
                                              : "CPF"
                                          ) +
                                          ":  \n              " +
                                          _vm._s(
                                            _vm.formatCpfCnpj(
                                              cdf.receiver_data.cpf_cnpj
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                cdf.mtrs.length > 0
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(
                                          "v-subheader",
                                          {
                                            staticClass:
                                              "pl-0 caption text-uppercase",
                                            staticStyle: { height: "32px" },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Manifestos Incluídos\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._l(cdf.mtrs, function (mtr, index) {
                                          return _c(
                                            "v-chip",
                                            {
                                              key: index,
                                              staticClass: "body-2",
                                              attrs: {
                                                "text-color": "white",
                                                color: "green",
                                                label: "",
                                                small: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(mtr.number) +
                                                  "\n            "
                                              ),
                                            ]
                                          )
                                        }),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm.pagination.totalPages > 1 && !_vm.isFetchingCdfs
                ? _c(
                    "v-layout",
                    { attrs: { "justify-center": "", "align-center": "" } },
                    [
                      _c("v-pagination", {
                        staticClass: "align-self-center",
                        attrs: {
                          length: _vm.pagination.totalPages,
                          color: "green darken-4",
                          circle: "",
                        },
                        on: { input: _vm.fetchGeneratorCdfs },
                        model: {
                          value: _vm.pagination.activePage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "activePage", $$v)
                          },
                          expression: "pagination.activePage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cdfsFiltereds.length === 0
                ? _c(
                    "empty-content",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mb-2",
                          attrs: { slot: "content", size: "56" },
                          slot: "content",
                        },
                        [_vm._v("mdi-inbox")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "headline grey--text text--darken-1",
                          attrs: { slot: "message" },
                          slot: "message",
                        },
                        [_vm._v("\n        Não há CDF's para exibir\n      ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isFetchingCdfs
                ? _c(
                    "v-layout",
                    {
                      attrs: {
                        "fill-height": "",
                        "align-content-center": "",
                        "justify-center": "",
                      },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "primary", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }